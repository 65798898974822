import KWNavbar from '../../components/navbar/navbar'
import KWFooter from '../../components/footer/footer'
import './Showing.css';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { LineWave } from 'react-loader-spinner';
import Moment from 'react-moment';
import moment from 'moment-timezone';
import 'moment-timezone'

// const { format } = require('date-fns-tz');


import { useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { sameAsLinks } from "../../constants/schemaConstants"; // Import common sameAs array
Moment.globalTimezone = 'America/Toronto'


export const Showing = () => {
    const [isLoading, setIsloading] = useState(true)
    const [itemData, setItemData] = useState({});
    const navigate = useNavigate()
    const fetchData = () => {
        axios.get(process.env.REACT_APP_API_URL + '/v1/showing/public').then((res) => {

            return res.data.groupedEvents;
        })
            .then((data) => {
                setItemData(data)
                setIsloading(false)
            })
            .catch(() => {
                setIsloading(false)
            })
    };
    useEffect(() => {
        fetchData()
    }, []);

    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    return (
        <>
            {/* Helmet for Meta Data */}
            <Helmet>
                <title>Schedule a Property Viewing | Hassle-Free Showings</title>
                <meta
                    name="description"
                    content="Book a property showing with K-W Property Management. Easily schedule viewings for rental homes, apartments & condos in Kitchener, Waterloo & Cambridge."
                />
                <meta
                    name="keywords"
                    content="Property showings Kitchener, Property showings Waterloo, Property showings Cambridge, Schedule a rental viewing, Book a property showing, Rental home tours Kitchener, Rental home tours Waterloo, Rental home tours Cambridge, Apartment viewings Kitchener, Apartment viewings Waterloo, Apartment viewings Cambridge, Find rental showings near me, Open house rental listings Ontario, Viewing appointments Kitchener-Waterloo, Hassle-free property visits"
                />
                <link rel="canonical" href="https://www.kwproperty.com/Showing" />
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Event",
                        "name": "Property Showings - K-W Property Management",
                        "description": "Book a property showing with K-W Property Management. Easily schedule viewings for rental homes, apartments & condos in Kitchener, Waterloo & Cambridge.",
                        "url": "https://www.kwproperty.com/Showing",
                        "eventStatus": "https://schema.org/EventScheduled",
                        "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
                        "location": {
                            "@type": "Place",
                            "name": "K-W Property Management",
                            "address": {
                                "@type": "PostalAddress",
                                "streetAddress": "541 Queen Street South",
                                "addressLocality": "Kitchener",
                                "addressRegion": "ON",
                                "postalCode": "N2G-1W9",
                                "addressCountry": "CA"
                            }
                        },
                        "organizer": {
                            "@type": "RealEstateAgent",
                            "name": "K-W Property Management",
                            "url": "https://www.kwproperty.com"
                        },
                         "sameAs": sameAsLinks
                    })}
                </script>
            </Helmet>
            <KWNavbar />
            <div className="pt-5">
                <div className="container showing-container">
                    <div className="mt-sm-3 mt-md-5 pt-1 px-2">
                        <div className="col-sm-12 col-md-10">
                            <div className="h1 pt-5 Upcoming-Propertyhead">Upcoming Property Showings</div>
                            <div className="mt-4 pt-4 Upcoming-property">Upcoming property showings are displayed below. If you would like to attend any, please click the corresponding 'BOOK SHOWING' link to confirm. Don't see the property you're interested in? No problem.
                                <Link to="/ScheduleShowing" className="Upcoming-property2" style={{ marginLeft: '5px' }}>
                                    Click here to book an appointment for another property.
                                </Link>
                                {/* <a href="/ScheduleShowing" className='Upcoming-property2'> Click here to book an appointment for another property.</a> */}
                                {/* <a href="#" className='Upcoming-property2'> Click here to book an appointment for another property.</a> */}
                            </div>
                        </div>
                        <div className="col-sm-12">
                            {
                                isLoading !== true ?
                                    itemData ?
                                        Object.keys(itemData)?.map((key) => {
                                            // const cDay = new Date(key)
                                            // cDay.toLocaleString('en-US', { timeZone: 'America/Toronto' })
                                            //const cDay = moment.tz(key, "America/Toronto");
                                            const cDay = moment.tz(key, "America/Toronto");
                                            // console.log(itemData)
                                            // console.log("cDay "+ cDay)
                                            // let d = new Date();


                                            // d.toLocaleString('en-US', { timeZone: 'America/Toronto' })
                                            // const today = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
                                            // const keyDate = key.split('-').reduce((a, b) => Number(a) + Number(b),)
                                            // const td = today.split('-').reduce((a, b) => Number(a) + Number(b),)
                                            // console.log("td  " + td);
                                            // console.log("keyDAte " + keyDate);
                                            // console.log("today" + today);
                                            const title = `${daysOfWeek[cDay.day()]} , ${months[cDay.month()]} ${cDay.date()}  ${cDay.year()}`


                                            const timeZone = 'America/Toronto';

                                            // Convert the date to the desired time zone using moment-timezone
                                            const formattedDate = moment.tz(key, timeZone);

                                            // Check if the date is today or tomorrow
                                            const isToday = moment().isSame(formattedDate, 'day');
                                            const isTomorrow = moment().add(1, 'day').isSame(formattedDate, 'day');

                                            // Set the formatted date based on the check
                                            let displayDate;
                                            if (isToday) {
                                                displayDate = 'Today';
                                            } else if (isTomorrow) {
                                                displayDate = 'Tomorrow';
                                            } else {
                                                displayDate = formattedDate.format('MMM DD, YYYY');
                                            }

                                            return (
                                                <>
                                                    <div className="secound-content mt-5">
                                                        <div className="content2-head">{displayDate}</div>
                                                        <div className="content2-border"></div>
                                                        <div className="content2-row">
                                                            {
                                                                itemData[key].map((item) => {
                                                                    if (item.details !== undefined) {

                                                                        const labl = new Date(item.start.dateTime);
                                                                        labl.toLocaleString('en-US', { timeZone: item.start.timeZone })
                                                                        let bookData = {
                                                                            // summery: item.details?.summery,
                                                                            title: title,
                                                                            showingDate: title,
                                                                            time: item.start.dateTime,
                                                                            eventId: item.id,
                                                                            extendedProperties: item.extendedProperties.shared
                                                                        }



                                                                        return (
                                                                            <div className="row mt-2">
                                                                                <div className="col-sm-12 col-md-2 time">
                                                                                    <Moment date={item.start.dateTime} format="hh:mm a" />
                                                                                </div>
                                                                                <div className="col-sm-12 col-md-6">
                                                                                    <p
                                                                                        style={{ cursor: 'pointer', marginBottom: '0px', }}
                                                                                        onClick={() => navigate(`/listing/${item.extendedProperties.shared.propertyId}`)} className='middle-link1'>{item.extendedProperties.shared.propertyTitle}</p>
                                                                                    <small style={{ color: "#999998", marginTop: '-5px', textAlign: 'left' }} className='d-block p-0'>{item.extendedProperties.shared.unitTitle}</small>
                                                                                    {/* <div className='content2-unit'>Unit C</div> */}
                                                                                </div>
                                                                                <div className="col-sm-12 col-md-4">
                                                                                    {
                                                                                        item.details.meetingStatus === "c" ? (
                                                                                            <button className='book-Showing-c btn btn-danger'>CANCELLED</button>
                                                                                        ) : (
                                                                                            <button className='book-Showing btn btn-primary' onClick={() => {
                                                                                                navigate(`/ConfirmProperty/${item.id}`, { state: { bookData } });
                                                                                            }}>BOOK SHOWING</button>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    return <></>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        })
                                        : ('')
                                    : (
                                        <div className="d-flex justify-content-center">
                                            <LineWave
                                                height="200px"
                                                width="300px"
                                                color="#0586f0"
                                                ariaLabel="line-wave"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                            />
                                        </div>
                                    )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <KWFooter />
        </>
    )
}