import React from 'react'
import "./teamcard.css"
import { Col, Row } from 'react-bootstrap'

export const Teamcard = ({name, designation, description, img}) => {
  return (
    
    <div className='mb-5 p-4 team-card'>

        <h2 className="blue">{name}</h2>

        <h4>{designation}</h4>
        <Row>
            <Col sm={12} lg={3} className='d-flex align-items-center justify-content-center my-3 my-lg-0'>
            <img src={img} className='image mb-4 mb-md-0' alt={`${name} – ${designation} at K-W Property Management`}></img></Col>     
            <Col sm={12} lg={9}><p className="lead content">{description}</p></Col>
        </Row>
    </div>

  )
}
