import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import "./listings.css";
import { useNavigate } from "react-router-dom";

import img from '../../assets/no-image.png';
import { FaBath, FaBed } from "react-icons/fa";
import { LiaVectorSquareSolid } from "react-icons/lia";

export const Listings = ({ listing }) => {

  const navigate = useNavigate();

  // Format numbers to include commas as thousand separators
  const formatNumber = (number) => number.toLocaleString('en-US');

  return (
    <Container className="p-2 p-md-5">
      <Row className="main-container">

        {/* Image Section */}
        <Col sm={12} lg={4} className="mx-auto d-block">
          <Image 
            src={listing.Property.Files || img} 
            alt={listing.Property.Name 
              ? `${listing.Property.Name} - ${listing.Property.Address.City}, ${listing.Property.Address.State}, ${listing.Property.Address.PostalCode} | Property for Rent | KW Property Listing` 
              : "Real Estate Property for Rent - KW Property Listing"}
            className="image-container mb-4 mb-lg-0" 
          />
        </Col>

        {/* Property Details Section */}
        <Col sm={12} lg={8} className="pt-2 property-details">
          <h3>{listing.Property.Name}</h3>
          <p>{listing.Property.PropertyUnitTypeTitle}</p>
          <p className="text-capitalize">
            Address: {listing.Property.Address.AddressLine1.toLowerCase()}
          </p>
          <div className="d-flex gap-4">
            <div>
              <FaBed title='Bedrooms' size={20} className="icon-primary" />
              <span className="text-capitalize">{" " + listing?.Unit?.UnitBedrooms.toLowerCase() || 'N/A'}</span>
            </div>
            <div className='d-flex gap-2 align-items-center'>
              <FaBath title='Bathrooms' size={20} className="icon-primary" />
              <span>{" " + listing?.Unit?.UnitBathrooms || 'N/A'}</span>
            </div>
            <div>
              <LiaVectorSquareSolid title='Sq Ft' size={20} className="icon-primary" />
              <span>{" " + (listing?.Unit?.UnitSize !== 0 ? listing?.Unit?.UnitSize : 'N/A')}</span>
            </div>
          </div>

          {/* Rent and Action Button */}
          <div className="d-flex justify-content-end align-items-end button-container pt-md-5">
            <h5 className="text-primary me-3 price-container">
              ${formatNumber(listing.Rent)}/month
            </h5>
            <Button 
              className="btn btn-primary view-button" 
              onClick={() => navigate(`/listing/${listing?.Unit?.Id}`)}
            >
              View
            </Button>

          </div>
        </Col>

      </Row>
    </Container>
  );
};
