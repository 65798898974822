import React, { useEffect, useMemo, useState } from 'react'
import './browse.css'
import { Container } from 'react-bootstrap'
import { Listings } from '../../components/listings/Listings'
import KWNavbar from '../../components/navbar/navbar'
import KWFooter from '../../components/footer/footer'
import { LineWave } from 'react-loader-spinner';

import './filters.css'
import { Col, Row } from 'react-bootstrap'
import Select from 'react-select'
import { Helmet } from 'react-helmet'
import { sameAsLinks } from "../../constants/schemaConstants"; // Import common sameAs array



export const Browse = () => {

  const LIMIT = 50;
  const OFFSET = 0;

  const [filterFlag, setFilterFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState(null);
  const [filterData, setFilterData] = useState();
  const [propertyList, setPropertyList] = useState(null);
  const [filteredProperty, setFilteredProperty] = useState([]);
  const [unitType, setUnitType] = useState('');
  const [priceRange, setPriceRange] = useState('');
  const [city, setCity] = useState('');
  const [bedroomCount, setBedroomCount] = useState('');
  const [sortBy, setSortBy] = useState('');


  const [checkedItems, setCheckedItems] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState({});


  //pagination system
  const [limit, setLimit] = useState(LIMIT);
  const [offset, setOffset] = useState(OFFSET);
  const [totalListings, setTotalListings] = useState(50);
  const [streetArr, setStreetArr] = useState([{ value: '', label: 'All Street' }])
  const [selectedStreet, setSelectedStreet] = useState(null)
  const [schemaData, setSchemaData] = useState({})

  const handleCheckboxChange = (data) => {
    setCheckboxStates((prevCheckboxStates) => ({
      ...prevCheckboxStates,
      [data]: !prevCheckboxStates[data],
    }));

    setCheckedItems((prevCheckedItems) =>
      checkboxStates[data]
        ? prevCheckedItems.filter((item) => item !== data)
        : [...prevCheckedItems, data]
    );
  }


  const fetchStreets = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL + '/v1/listing/filters';

      const response = await fetch(apiUrl)
      const data = await response.json()
      setStreetArr((p) => ([...p, ...data.streets.map(d => ({
        value: d.streetName, label: (
          <div style={{ textAlign: 'left' }}>
            <span style={{ textAlign: 'left' }}>{d.streetName}</span>
          </div>
        )
      }))]))
      data.cities = Array.from(new Set(data.cities.map(c => c.toLowerCase())));
      setFilterData(data);
      console.log(data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  useMemo(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/v1/listing/kwpweb`;
    setIsLoading(true)
    fetchStreets()
    fetch(apiUrl, {
      method: 'GET'
    })
      .then(response => {
        return response.json()
      })
      .then(responseData => {
        setPropertyList(responseData);
        setFilteredProperty(responseData)
        setIsLoading(false)
        setTotalListings(responseData.length);
      })
      .catch(error => {
        setIsLoading(false)
        console.error('Error fetching data:', error);
      });
  }, []);


  useEffect(() => {
    setData(filteredProperty)
  }, [filteredProperty])

  //on submit function for handle the search.
  const onSubmit = () => {
    let filterConditions = []

    if (unitType) {
      if (unitType !== 'A') {
        // Residential Commercial Student
        const regex = unitType === "Commercial" ? /commercial/ : unitType === "Student" ? /student/ : /commercial|student/
        let condition;
        if (unitType === "Residential") {
          condition = item => !regex.test(item.Unit.propertSubyType.toLowerCase());
        } else {
          condition = item => regex.test(item.Unit.propertSubyType.toLowerCase());
        }
        filterConditions.push(condition);
      }
    }

    if (priceRange) {
      if (priceRange !== 'A') {
        const [startString, endString] = priceRange.split('-');
        const minRent = parseInt(startString, 10);
        if (endString === '+') {
          // const condition = item => item.Unit.MarketRent >= minRent;
          const condition = item => item.Rent >= minRent;
          filterConditions.push(condition);
        } else {
          const maxRent = parseInt(endString, 10);
          // const condition = item => item.Unit.MarketRent >= minRent && item.Unit.MarketRent <= maxRent;
          const condition = item => item.Rent >= minRent && item.Rent <= maxRent;
          filterConditions.push(condition);
        }
      }
    }

    if (city) {
      if (city !== 'A') {
        const condition = item => item.Property.Address.City.toLowerCase() === city.toLowerCase();
        filterConditions.push(condition);
      }
    }

    if (bedroomCount) {
      if (bedroomCount !== 'A') {

        let bedroomCountArray = []

        if (bedroomCount === 'OneBed') {
          bedroomCountArray.push('ONE')
        } else if (bedroomCount === 'TwoBed') {
          bedroomCountArray.push('TWO')
        } else if (bedroomCount === 'ThreeBed') {
          bedroomCountArray.push('THREE')
        } else if (bedroomCount === '3 or more') {
          bedroomCountArray.push('THREE')
          bedroomCountArray.push('FOUR')
        } else if (bedroomCount === '4 or more') {
          // bedroomCount.push('ThreeBed')
          bedroomCountArray.push('FOUR')
        } else {
          bedroomCountArray = []
        }

        if (bedroomCountArray && bedroomCountArray.length > 0) {
          const condition = item => bedroomCountArray.includes(item.Unit.UnitBedrooms);
          filterConditions.push(condition);
        }

      }
    }

    if (selectedStreet !== null) {
      if (selectedStreet.value) {
        const condition = item => item.streetName === selectedStreet.value;
        filterConditions.push(condition)
      }
    }

    if (checkedItems && checkedItems.length > 0) {
      const condition = item => checkedItems.every(feature => item.Unit.Features.includes(feature));
      filterConditions.push(condition);
    }

    let filteredPropertyList = propertyList.filter(item => {

      setFilterFlag(true);

      return filterConditions.every(condition => condition(item));
    });

    setOffset(0)

    if (filteredPropertyList.length < LIMIT) {

      setLimit(filteredPropertyList.length)

    }
    else {
      setLimit(LIMIT)
    }
    if (sortBy) {
      filteredPropertyList = sortProperty(sortBy,filteredPropertyList)
    }
    // setOffset(0)

    setFilteredProperty(filteredPropertyList)
    setTotalListings(filteredPropertyList.length)
  }
  const sortProperty = (sortValue, properties = propertyList) => {
    let sortedProperty = [...properties];
    setSortBy(sortValue);
    if (sortValue === 'lth') {
      sortedProperty.sort((a, b) => a.Rent - b.Rent); // Low to High
    } else if (sortValue === 'htl') {
      sortedProperty.sort((a, b) => b.Rent - a.Rent); // High to Low
    }
    return sortedProperty;
  };

  const sortByRent = (sortValue, properties = filteredProperty) => {
    let sortedProperty = sortProperty(sortValue, properties);

    setLimit(LIMIT);
    setFilteredProperty(sortedProperty);
    setTotalListings(sortedProperty.length);
};




  useEffect(() => {

    console.log('Filtered Property Length : ' + filteredProperty.length)

    if (filteredProperty && filteredProperty.length > 0) {
      // Use slice to get a portion of the array based on offset and limit
      const filteredArray = filteredProperty.slice(offset, offset + limit);
      setData(filteredArray)


    } else if (!filterFlag) {

      // fetchData(limit, offset);
    }
  }, [limit, offset, filteredProperty, filterFlag]);

  const handleNextPage = () => {
    if (offset < totalListings) {
      setOffset(offset + limit);
    }
  };

  const handlePrevPage = () => {
    if (totalListings > limit) {
      setOffset(offset - limit);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }, []);

  useEffect(() => {

    const schemaDataJson = {
      "@context": "https://schema.org",
      "@type": "ItemList",
      "name": "Available Rental Properties",
      "description": "Find rental properties in Kitchener, Waterloo, and Cambridge managed by K-W Property Management.",
      "url": "https://www.kwproperty.com/browse",
      "numberOfItems": propertyList?.length,
      "itemListElement": propertyList?.map((property, index) => ({
        "@type": "Apartment",
        "name": property.Property.Name,
        "url": `https://www.kwproperty.com/property/${property.Unit.Id}`,
        "image": property.Property.Files,
        "description": property.Property.PropertyUnitTypeTitle,
        "address": {
          "@type": "PostalAddress",
          "streetAddress": property.Property.Address.AddressLine1,
          "addressLocality": property.Property.Address.City,
          "addressRegion": property.Property.Address.State,
          "postalCode": property.Property.Address.PostalCode,
          "addressCountry": "CA"
        }
      })),
      "sameAs": sameAsLinks
    };

    setSchemaData(schemaDataJson);

}, [propertyList]);
  return (
    <>
    {/* Helmet for Meta Data */}
    <Helmet>
      <title>Find Rental Homes & Apartments | Kitchener-Waterloo</title>
      <meta 
        name="description" 
        content="Browse rental listings in Kitchener, Waterloo & Cambridge. Find professionally managed apartments, condos & homes for rent. Start your search today!" 
      />
      <meta 
        name="keywords" 
        content="Rental listings Kitchener, Rental listings Waterloo, Rental listings Cambridge, Apartments for rent Kitchener, Apartments for rent Waterloo, Apartments for rent Cambridge, Condos for rent Kitchener, Condos for rent Waterloo, Condos for rent Cambridge, Homes for rent Kitchener, Homes for rent Waterloo, Homes for rent Cambridge, Property search Kitchener-Waterloo, Available rentals in Ontario, Find a rental home in Kitchener" 
      />
      <link rel="canonical" href="https://www.kwproperty.com/browse" />
      {/* Defines the schema.org context for structured data */}
      <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
    </Helmet>
      <KWNavbar />
      <Container className='mt-5 pt-5'>
        <h1 className='mobileResponsive-text'>Browse Listings and Filter Search</h1>
        {/* <Filters /> */}
        <div className='background background-padding'>
          <Row>
            <Col>
              <div className="row">
                {
                  filterData ?(
                    <>
                    <div className="col-sm-6 col-lg-3 mt-3">
                  <select className='required-number required-number2 w-100'
                    value={unitType}
                    onChange={(e) => setUnitType(e.target.value)}
                    name="unitType"
                    id="UnitType">
                    <option value="A">All Unit Types</option>
                    {

                      filterData ? (

                        filterData?.propertyTypes.length > 0 ? (

                          filterData?.propertyTypes.map((data) => {
                            return (
                              <>
                                <option value={data.type.trim().split(' ')[0]}>{data.type}</option>
                              </>
                            )
                          })
                        ) : ''
                      ) : ''
                    }
                  </select>
                </div>
                <div className="col-sm-6 col-lg-3 mt-3">
                  <select className='required-number required-number2 w-100'
                    value={priceRange}
                    onChange={(e) => setPriceRange(e.target.value)}
                    name="priceRange"
                    id="PriceRange">
                    <option value="A">All Price Range</option>
                    {

                      filterData ? (

                        filterData?.priceRange.length > 0 ? (

                          filterData?.priceRange.map((data) => {
                            return (
                              <>
                                <option value={`${data.minPrice}-${data.maxPrice}`}>
                                  {`${data.maxPrice}` === '+' ? `${data.minPrice}${data.maxPrice}` : `${data.minPrice}-${data.maxPrice}`}
                                </option>
                              </>
                            )
                          })
                        ) : ''
                      ) : ''
                    }
                  </select>
                </div>
                <div className="col-sm-6 col-lg-3 mt-3">
                  <select className='required-number text-capitalize required-number2 w-100 browse-select'
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    name="city"
                    id="City">
                    <option value="A">Select City</option>
                    {

                      filterData ? (

                        filterData?.cities.length > 0 ? (

                          filterData?.cities.map((data) => {
                            return (
                              <>
                                <option className='text-capitalize' value={data}>
                                  {data}
                                </option>
                              </>
                            )
                          })
                        ) : ''
                      ) : ''
                    }
                  </select>
                </div>
                <div className="col-sm-6 col-lg-3 mt-3">
                  <select className='required-number required-number2 w-100 browse-select'
                    value={bedroomCount}
                    onChange={(e) => setBedroomCount(e.target.value)}
                    name="bedroomCount"
                    id="BedroomCount">
                    <option value="A"># of Bedrooms</option>
                    {

                      filterData ? (

                        filterData?.numberOfBedRooms.length > 0 ? (

                          filterData?.numberOfBedRooms.map((data) => {
                            return (
                              <>
                                <option value={data.code}>
                                  {data.type}
                                </option>
                              </>
                            )
                          })
                        ) : ''
                      ) : ''
                    }
                  </select>
                </div>
                <div className="custom-select-container col-sm-6 col-lg-3 mt-3">
                  <Select
                    options={streetArr}
                    onChange={(d) => setSelectedStreet(d)}
                    placeholder="Select Street"
                    isClearable={true}
                  />
                </div>
                    </>
                  ):(
                    <>
                    <div className='fs-4'>Loading.....</div>
                    </>
                  )
                }
              </div>
            </Col>
            <Col sm={12} lg={12}>
              <Row className='mt-3'>
                <Col sm={12} lg={12}>
                  <div className="browse-grid">
                    {
                      filterData?.features && filterData.features.length > 0 ? (
                        filterData.features.map((data) => (
                          // <div className="browse-grid">
                          <label key={data} className='ms-2'>
                            <input
                              type="checkbox"
                              checked={checkboxStates[data] || false}
                              onChange={() => handleCheckboxChange(data)}
                              className='form-check-input mx-2'
                              value={data}
                            />
                            {/* {data} */}
                            {/* <label className=''>{data}</label> */}
                            <span className=''>{data.replace(/([A-Z])/g, ' $1')}</span>
                          </label>
                          // </div>
                        ))
                      ) :
                        <div className='py-5'>
                          <div className="d-flex justify-content-center browse-linewave">
                            <LineWave
                              height="200px"
                              width="300px"
                              color="#0586f0"
                              ariaLabel="line-wave"
                              wrapperStyle={{}}
                              wrapperClass=""
                              visible={true}
                              id="LineWave"
                            />
                          </div>
                        </div>
                    }
                  </div>
                </Col>
              </Row>
              <div className='sorting-div'>
                <select
                  onChange={(e) => sortByRent(e.target.value)}
                  className='required-number required-number2' name="sort" id="">
                  <option disabled selected value="">Sort by Rent</option>
                  <option value="lth">Low to high</option>
                  <option value="htl">High to low</option>
                </select>
              </div>
              <div className="button-div">
                <button onClick={onSubmit} className='btn btn-primary'>Search</button>
              </div>
            </Col>
          </Row>

        </div>


        <div className="listings-container">
          {((data && data.length > 0) || (filteredProperty && filteredProperty.length > 0)) || filterFlag ? (
            <>
              {data && data.length > 0 && data.map(listing => (
                <Listings key={listing.Unit.Id} listing={listing} />
              ))}

              {!data && filteredProperty.length > 0 && filteredProperty.map(listing => (
                <Listings key={listing.Unit.Id} listing={listing} />
              ))}

              {data.length === 0 && filteredProperty.length === 0 && filterFlag && (
                <div className="d-flex justify-content-center">No Property Found!</div>
              )}
            </>
          ) : (
            <div className="d-flex justify-content-center">
              {isLoading && <LineWave
                height="200px"
                width="300px"
                color="#0586f0"
                ariaLabel="line-wave"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />}
              {!isLoading && <div className="d-flex justify-content-center">No Property Found!</div>}
            </div>
          )}
        </div>

        <div className="pagnation">
          <div className="pagnation-button-div">
            <div className='first-button'>
              <button onClick={handlePrevPage} disabled={offset === 0} className='btn previous-button btn-primary'>
                Previous Page
              </button>
            </div>
            <div className='button-text'> Listings {offset === 0 ? (`${limit === 0 ? limit : '1'} - ${limit > totalListings ? totalListings : limit}`) : (`${(offset + 1)}-${((offset + limit) >= totalListings) ? totalListings : (offset + limit)}`)} of {totalListings} </div>
            <div className='secound-button'>
              {/* <button onClick={handleNextPage} disabled={offset === totalListings}> */}
              <button onClick={handleNextPage} disabled={(offset + limit) >= totalListings} className='btn next-button btn-primary'>
                Next Page
              </button>
            </div>
          </div>
        </div>
      </Container>
      <KWFooter />
    </>
  )
}
